import AOS from 'aos';

$(function() {

    // Initialize AOS animations
    AOS.init({ duration: 1000 });

    // Navigation
    $('.navbar-toggler').on('click', function () {
        if ($(window).width() <= 992) {
            showMobileNavigation();
        }
    })

    $(".nav-item").click(function(e) {
        if ($(window).width() <= 992) {
            hideMobileNavigation();
        }
    })

    // Scroll down
    $(".scrollDownWrapper").click(function() {
        $('html, body').animate({
            scrollTop: $("#welcome").offset().top
        }, 500);
    });

    // Accordion
    $(".card-header").click(function() {
        let clickedElement = $(this);
        clickedElement.find("button").toggleClass("minus");

        $(".card-header").each(function() {
            if (!$(this).is(clickedElement)) {
                $(this).find("button").removeClass("minus");
            }
        })
    })

    function showMobileNavigation() {
        let animatedIcon = $('.animated-icon');
        animatedIcon.toggleClass('open');
        $('nav').toggleClass('show');
        if (animatedIcon.hasClass('open')) {
            $('html').css('overflow', 'hidden');
            $('body').css('overflow', 'hidden');
        } else {
            $('html').css('overflow', 'initial').css('overflow-x', 'hidden');
            $('body').css('overflow', 'initial').css('overflow-x', 'hidden');
        }
    }

    function hideMobileNavigation() {
        let animatedIcon = $('.animated-icon');
        animatedIcon.toggleClass('open');
        $('nav').toggleClass('show');
        $('.navbar-collapse').toggleClass('show');

        if (animatedIcon.hasClass('open')) {
            $('html').css('overflow', 'hidden');
            $('body').css('overflow', 'hidden');
        } else {
            $('html').css('overflow', 'initial').css('overflow-x', 'hidden');
            $('body').css('overflow', 'initial').css('overflow-x', 'hidden');
        }
    }
})
